<template>
	<div>
		<!-- 列表页内容区 -->
		<tableList ref="TableList" @buttonMethods="buttonMethods" @itemButtonMethods="itemButtonMethods" :sUrl="sUrl"></tableList>
		<!-- 异常提示 -->
		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
			<el-table :data="tableData" style="width: 100%">
				<el-table-column prop="name" label="姓名" width="180">
				</el-table-column>
				<el-table-column prop="phone" label="电话" width="180">
				</el-table-column>
				<el-table-column prop="mark" label="备注">
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 分配客户 -->
		<el-dialog title="选择活动" :visible.sync="allotCustomer" width="30%">
			<el-form :model="form" label-width="80px" label-position="right">
				<el-row :gutter="20">
					<el-col :span="18" :push="2">
						<el-form-item label="选择用户" prop="role">
							<el-row :gutter="20">
								<el-col :span="20">
								<el-select v-model="form.user_id" placeholder="请选择用户" style="width:100%">
										<el-option :label="item.true_name" :value="item.id"
											v-for="(item,key) in arrUser" :key="key">
										</el-option>
									</el-select>
								</el-col>
							</el-row>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row :gutter="20">
					<el-col :span="18" :push="2">
						<el-form-item label="备注" prop="nickname">
							<el-row :gutter="20">
								<el-col :span="20">
									<el-input v-model="form.remark" placeholder="">
									</el-input>
							</el-col>
							</el-row>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="allotCustomer = false">取 消</el-button>
				<el-button type="primary" @click="changeUser">确 定</el-button>
			</span>
		</el-dialog>
		
		<!-- 查看详情 -->
		<el-drawer
		  :withHeader="false"
		  :visible.sync="showInfo"
		  direction="rtl"
		  size="65%"
		  :close-on-press-escape="false"
		  :wrapperClosable="false"
		  @closed="referList()"
		  >
		  <tableDrawer @closeDrawer="closeDrawer" v-if="showInfo" :itemData="itemData"></tableDrawer>
		</el-drawer>
		<!-- 作品列表 -->
		<el-dialog title="管理客户作品" :visible.sync="showWorkBox" width="55%"
			:close-on-click-modal="false" :close-on-press-escape="false" append-to-body center>
			<el-table :data="worksList" stripe style="width: 100%">
				<el-table-column prop="type" label="作品类型" align="center"></el-table-column>
				<el-table-column prop="content" label="作品内容" align="center" show-overflow-tooltip></el-table-column>
				<el-table-column prop="step" label="作品状态" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.step == 0">
							<span class="tag" style="background-color:#00BFFF"></span>未提审
						</div>
						<div v-if="scope.row.step == 1">
							<span class="tag" style="background-color:#00CED1"></span>待审核
						</div>
						<div v-if="scope.row.step == 2">
							<span class="tag" style="background-color:#32CD32"></span>审核通过
						</div>
					</template>
				</el-table-column>
				<el-table-column  label="操作" width="160" align="center">
					<template slot-scope="scope">
						<el-button type="text" v-if="scope.row.step != 2" @click="audit(scope.row)">审核</el-button>
					</template>
				</el-table-column>
			  </el-table>
		</el-dialog>
		<!-- 处理作品 -->
		<el-dialog
		  modal-append-to-body
		  :visible.sync="showWork"
		  width="30%"
		  center
		  fullscreen
		  >
		  <workDrawer :workInfo="workInfo" :activityId="activityId" :arrCustomer="arrCustomer" :worksList="worksList" @closeShowWork="closeShowWork"></workDrawer>
		</el-dialog>
		<!-- 发送短信 -->
		<el-dialog
		  modal-append-to-body
		  :visible.sync="showSmsDialog"
		  width="30%"
		  center
		  >
		  <el-form>
			  <el-row :gutter="20">
				<el-col :span="18" :push="2">
					<el-form-item label="选择短信模板" prop="role">
						<el-row :gutter="20">
							<el-col :span="20">
							<el-select v-model="nSmsTemplate" placeholder="请选择模板" style="width:100%">
									<el-option :label="item.name" :value="item.id" v-for="(item,key) in arrTemplate" :key="key">
									</el-option>
								</el-select>
							</el-col>
						</el-row>
					</el-form-item>
				</el-col>
			  </el-row>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		  	<el-button @click="showSmsDialog = false">取 消</el-button>
		  	<el-button type="primary" @click="requestSend()">确 定</el-button>
		  </span>
		</el-dialog>
		
	</div>
</template>

<script>
	import oIndex from "@/js/customer/index.js";
	import tableList from '@/components/TableList.vue';
	import tableDrawer from '@/views/customer/index/edit.vue';
	import workDrawer from '@/views/customer/index/work.vue';
	export default {
		components: {
		    tableList,
			tableDrawer,
			workDrawer
		},
		data() {
			return {
				showWorkBox:false, // 展示作品弹窗
				nSmsTemplate: '', // 短信模板ID
				showSmsDialog: false,
				showWork:false, // 展示作品
				showInfo:false,// 抽屉开关
				arrActivity:[], // 活动信息
				sUrl : 'crm.customer_allot', // 当前页面路径
				// 弹出窗口数据
				form: {
					remark: '',
					user_id: '',
					activity_id:'',
				},
				dialogVisible: false,
				allotCustomer: false,
				arrCustomerIds: [],
				tableData: [],
				arrUser: [],
				itemData : {},
				arrTemplate: [], // 短信模板列表
				worksList: [], //作品列表
				workInfo: {}, // 作品详情
				activityId: '', // 活动ID
				nWorkId: '', // 作品ID
				arrCustomer:{}, // 客户信息
			};
		},
		watch:{
			showWorkBox(newValue, oldValue){
				if (newValue == false) {
					this.referList()
				}
			}
		},
		
		methods: {
			/**
			 * 顶部按钮点击事件
			 * @param {Object} action 触发方法
			 * @param {Object} param 携带参数
			 */
			buttonMethods(action, param) {
				console.log('触发方法：'+action)
				this[action](param)
			},
			/**
			 * 行内按钮点击事件
			 * @param {Object} action 触发方法
			 * @param {Object} param 携带参数
			 */
			itemButtonMethods(action, param){
				
				console.log('触发方法：'+action)
				this[action](param)
			},
			// 批量分配客户
			allot(param){
				this.getUserList()
				this.getActivityList()
				this.arrCustomerIds = param
				this.allotCustomer = true
				this.form.user_id = '';
			},
			/**
			 * 刷新列表
			 */
			referList(){
				this.$refs.TableList.getListApi();
			},
			// 确认分配客户
			changeUser() {
				let data = {
					ids: JSON.stringify(this.arrCustomerIds),
					user_id: this.form.user_id,
					activity_id : this.form.activity_id,
					remark: this.form.remark,
				};
				console.log(data)
				oIndex.allotCustomer(data).then((res) => {
					console.log()
					if (res.code == 403) {
						this.dialogVisible = true;
						this.allotCustomer = false;
						this.tableData = res.data;
					} else {
						this.$message.success(res);
						this.allotCustomer = false;
						this.$refs.TableList.getListApi();
					}
				});
			},
			// 获取员工列表
			getUserList() {
				let data = {act_id:1};
				oIndex.getUserList(data).then((res) => {
					this.arrUser = res
				});
			},
			// 获取员工列表
			getActivityList() {
				oIndex.getActivityList().then((res) => {
					this.arrActivity = res
				});
			},
			/**
			 * 开始沟通客户
			 * @param {Object} param
			 */
			contact(param){
				let data = {
					id:param.id
				};
				oIndex.contactCustomer(data).then((res) => {
					this.$refs.TableList.getListApi();
				});
			},
			// 获取客户详情
			getInfos(param){
				this.showInfo = true;
				this.itemData = param
			},
			/**
			 * 关闭详情抽屉
			 */
			closeDrawer(){
				this.showInfo=false;
			},
			/**
			 * 打开作品管理抽屉
			 */
			getWork(param){
				this.arrCustomer = param;
				this.nWorkId = param.id;
				this.getWorkList()
			},
			getWorkList(){
				let data = {
					id : this.nWorkId
				}
				oIndex.getEditInfo(data).then((res) => {
					this.worksList = res.work
					this.activityId = res.info.activity_id
					this.showWorkBox = true;
				})
			},
			/**
			 * 审核/作图
			 * @param {Object} param
			 */
			audit(param){
				console.log(param)
				this.workInfo = param
				this.showWork = true;
			},
			closeShowWork(){
				this.getWorkList();
				this.showWork = false;
				
			},
			/**
			 * 发送营销短信
			 */
			sendSms(param){
				this.arrCustomerIds = param;
				if (param.length <= 0) {
					this.$message.error('请选择客户');
					return;
				}
				oIndex.getSmsTemplate().then((res) => {
					if (res.length <= 0) {
						this.$message.error('请先设置模板');
						return;
					}
					this.arrTemplate = res
				})
				
				this.showSmsDialog = true;
			},
			/**
			 * 提交短信发送请求
			 */
			requestSend(){
				if (this.nSmsTemplate.length <= 0) {
					this.$message.error('请选择短信模板');
					return;
				}
				let data = {
					customer_ids: JSON.stringify(this.arrCustomerIds),
					template_id : this.nSmsTemplate
				}
				oIndex.sendSms(data).then((res) => {
					this.showSmsDialog = false
				});
			}
			
		},
	};
</script>

<style lang="less" scoped>
	/deep/ .el-drawer__body{
		overflow:visible !important;
	}
	/deep/ .el-drawer{
		overflow:visible !important;
	}
	.el-breadcrumb {
		margin-bottom: 15px;
		// font-size: 12px;
	}


	.icon-exchangerate {
		font-size: 30px;
		float: right;
		text-align: center;
	}

	.icon-exchangerate:hover {
		font-size: 32px;
		color: #00FFFF;
	}

	.tag {
		position: relative;
		top: -1px;
		display: inline-block;
		width: 6px;
		height: 6px;
		vertical-align: middle;
		border-radius: 50%;
		margin-right: 6px;
	}
</style>
